import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import router from "../router";

Vue.use(VueI18n);
Vue.use(VueCookies)


var messages = {
    en: require('../assets/lang/en.json'),
    th: require('../assets/lang/th.json'),
}

export const i18n = new VueI18n({
    locale: VueCookies.get('language'), // set locale
    fallbackLocale: VueCookies.get('language'),
    messages
})