<template>
  <div id="app">
    <div class="main-container">
      <div class="bg-main-img"></div>
      <b-overlay :show="isLoading" rounded="sm" spinner-variant="warning">
        <Header />
        <b-container>
          <b-row>
            <b-col>
                <router-view />
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Header from "@/views/layout/Header/Header.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Header
  },
  computed: {
    ...mapGetters({ isLoading: 'getIsLoadingGlobal' }),
  },
}
</script>

<style lang="scss">
.bg-main-img {
  position: fixed;
  height: 100%;
  overflow: auto;
  bottom: 0;
  width: 100%;
  
  background-image: url("./assets/images/bg-main.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
.main-container {
  margin: auto;
  min-height: 100vh;
  color: black;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
