import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/page/redirect/index.vue")
  },
  {
    path: "/financial-crisis-test",
    name: "financial-crisis-test",
    component: () => import("@/views/page/financial-crisis-test/index.vue")
  },
  {
    path: "/retirement-planning",
    name: "retirement-planning",
    component: () => import("@/views/page/retirement-planning/index.vue")
  },
  {
    path: "/term-and-condition",
    name: "term-and-condition",
    component: () => import("@/views/page/term-and-condition/index.vue")
  },
  {
    path: "/session-expired",
    name: "session-expired",
    component: () => import("@/views/page/error/session-expired.vue")
  },
  {
    path: "/success/:action",
    name: "success",
    component: () => import("@/views/page/landing/index.vue")
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

export default router;
