import Vue from "vue";
import Vuex from "vuex";
// import liff from "@line/liff";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isInLineLiff: false,
    addressSelected:null,
    isLoadingGlobal:true
  },
  mutations: {
    UPDATE_IS_IN_LINE_LIFF: (state, payload) => {
      Vue.set(state, "isInLineLiff", payload);
    },
    UPDATE_ADDRESS: (state, payload) => {
      Vue.set(state, "addressSelected", payload);
    },
    UPDATE_IS_LOADING_GLOBAL: (state, payload) => {
      Vue.set(state, "isLoadingGlobal", payload);
    }
  },
  actions: {
    setIsInLineLiff(context, payload) {
      context.commit("UPDATE_IS_IN_LINE_LIFF", payload);
    },
    setAddress(context, payload) {
      context.commit("UPDATE_ADDRESS", payload);
    },
    setIsLoadingGlobal(context, payload){
      context.commit("UPDATE_IS_LOADING_GLOBAL", payload);
    }
  },
  getters: {
    getIsInLineLiff: state => {
      return state.isInLineLiff;
    },
    getAddress: state => {
      return state.addressSelected;
    },
    getIsLoadingGlobal: state => {
      return state.isLoadingGlobal;
    }
  },
  modules: {}
});
